<template>
  <div id="container">
    <div id="main">
      <div class="left">
        <img src="@/assets/img/login.png" alt="" style="width: 100%">
      </div>
      <div id="loginForm">
        <el-form :model="user" style="width: 80%" :rules="rules" ref="loginRef">
          <div style="font-size: 20px; font-weight: bold; text-align: center; margin-bottom: 20px">
            欢迎登录 XiaohuaBlog
          </div>
          <el-form-item prop="username">
            <el-input prefix-icon="el-icon-user" size="medium" placeholder="请输入账号"
                      v-model="user.username"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input prefix-icon="el-icon-lock" size="medium" show-password placeholder="请输入密码"
                      v-model="user.password"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <div style="display: flex">
              <el-input placeholder="请输入验证码" prefix-icon="el-icon-circle-check" size="medium" style="flex: 2"
                        v-model="user.code"></el-input>
              <div style="flex: 1; height: 36px;padding: 5px;">
                <valid-code @update:value="getCode"/>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="login">登 录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import ValidCode from "@/components/ValidCode.vue";
import {setRoutes} from "@/router";
import {resetRouter} from "@/router";
export default {
  name: "Login",
  components: {
    ValidCode
  },
  data() {

    // 验证码校验
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))

      } else if (value.toLowerCase() !== this.code) {
        callback(new Error('验证码错误'))
      } else {
        callback()
      }
    }

    return {
      code: '',  // 验证码组件传递过来的code
      user: {
        code: '',   // 表单里用户输入的code 验证码
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          {min: 5, max: 10, message: '长度在 5 到 10 个字符', trigger: 'blur'},
          {pattern: '^[a-zA-Z][a-zA-Z0-9_]{4,9}$', message: '必须以字母开头，可包含字母数字下划线'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur'},
          {
            pattern: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$',
            message: '必须包含大小写字母和数字，不能使用特殊字符'
          }
        ],
        code: [
          {validator: validateCode, trigger: 'blur'}
        ],
      }
    }
  },
  created() {
  },
  methods: {
    getCode(code) {
      this.code = code.toLowerCase()
    },
    login() {
      this.$refs['loginRef'].validate((valid) => {
        if (valid) {
          // 验证通过
          this.$request.post('/admin/login', this.user).then(res => {
            if (res.code === '200') {
              localStorage.setItem("honey-user", JSON.stringify(res.data));  // 存储用户数据
              localStorage.setItem("menus", JSON.stringify(res.data.menus));  // 存储用户菜单
              //动态设置当前用户路由
              setRoutes();
              this.$message.success('登录成功')
              this.$router.push('/home')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
#container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0F9876;
}

#main {
  display: flex;
  background-color: white;
  width: 50%;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
}

#loginForm {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1140px) {
  #main {
    width: 60%;
  }
}

@media screen and (max-width: 996px) {
  #main {
    width: 65%;
  }
}
@media screen and (max-width: 768px) {
  #main {
    width: 50%;
  }

  div.left {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  #main {
    width: 80%;
  }
}
</style>