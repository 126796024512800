import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'

const View = {render: h => h('router-view')}

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}
//刷新页面会重置路由
export const setRoutes = () => {
    const storeMenus = JSON.parse(localStorage.getItem("honey-user") || '{}').menus;
    if (storeMenus) {
        const manageRoute = {
            path: '/',
            name: 'Manage',
            redirect: '/home',
            component: () => import('../views/Manage.vue'),
            children: []
        }
        const menus = toTree(storeMenus)
        manageRoute.children = menus
        //console.log(manageRoute)
        //console.log(menus)
        //动态加载到想在的路由对象
        router.addRoute(manageRoute)
        //console.log(routes)
    }
}

//树形结构
function toTree(data) {
    return data.map(item => {
        return {
            path: item.path || '',
            name: item.name || '',
            meta: item.activeMenu == null ? {} : {'activeMenu': item.activeMenu},
            component: item.pagePath == null ? View : () => import(`../views/Manager/${item.pagePath}.vue`),
            redirect: item.redirect || '',
            children: item.children && item.children.length > 0 ? toTree(item.children) : []
        }
    })
}

//路由守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        const token = JSON.parse(localStorage.getItem("honey-user") || '{}').token
        if (!token) {
            next('/login')
        } else {
            next()
        }
    }
})
export default router

