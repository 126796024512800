import Vue from 'vue'
import App from './App.vue'
import router, {setRoutes} from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'
import VueAnimateNumber from 'vue-animate-number'

import '@/assets/css/style.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/font/Oswald-Stencil.ttf'
import request from "@/utils/request";
import utils from "@/utils/utils";

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' });
Vue.prototype.$request=request
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL
Vue.prototype.$moment = moment;

Vue.use(VueAnimateNumber)

setRoutes();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
